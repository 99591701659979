

// Links
//
// Style anchor elements.

$link-color:                              $warning !default;
$link-decoration:                         none !default;
$link-hover-color:                        shade-color($link-color, 15%) !default;
$link-hover-decoration:                   none !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

$stretched-link-pseudo-element:           after !default;
$stretched-link-z-index:                  1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   0 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-sans-serif:             "Nunito" !default;
$font-display:                $font-sans-serif !default;
$font-monospace:              null !default;
$font-serif:                  null !default;

$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$font-size-xs:                $font-size-base * .8 !default;
$font-size-sm:                $font-size-base * .9 !default;
$font-size-md:                $font-size-base * 1.125 !default;
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-xl:                $font-size-base * 1.5 !default;
$font-size-2xl:               $font-size-base * 2 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          800 !default;
$font-weight-black:           900 !default;

$font-weight-base:            $font-weight-normal !default;

$line-height-base:            1.625 !default;
$line-height-sm:              1.3 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.25 !default;
$h2-font-size:                $font-size-base * 1.75 !default;
$h3-font-size:                $font-size-base * 1.375 !default;
$h4-font-size:                $font-size-base * 1.125 !default;
$h5-font-size:                $font-size-base * 1 !default;
$h6-font-size:                $font-size-base * .875 !default;

$headings-margin-bottom:      0 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        $font-weight-semibold !default;
$headings-line-height:        1.3 !default;
$headings-color:              $gray-900 !default;

$large-headings-font-weight:  $headings-font-weight !default;
$small-headings-font-weight:  $font-weight-semibold !default;


// scss-docs-start display-headings
$display-font-sizes: (
    1: 5rem,
    2: 4.5rem,
    3: 4rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem
) !default;

$display-font-family:        $font-display !default;
$display-font-weight:        $font-weight-bolder !default;
$display-line-height:        $headings-line-height !default;
$display-color:              $headings-color !default;
// scss-docs-end display-headings

$lead-font-size:              $font-size-base * 1.125 !default;
$lead-font-weight:            $font-weight-normal !default;

$small-font-size:             .875em !default;

$sub-sup-font-size:           .75em !default;

$text-muted:                  tint-color($gray-800, 7%) !default;
$text-muted-hover:            shade-color($text-muted, 15%) !default;

$initialism-font-size:        $small-font-size !default;

// $blockquote-margin-y:         $spacer !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;
$blockquote-footer-color:     $gray-600 !default;
$blockquote-footer-font-size: $small-font-size !default;

// $hr-margin-y:                 $spacer !default;
$hr-color:                    $border-color !default;
$hr-height:                   $border-width !default;
$hr-opacity:                  1 !default;

$legend-margin-bottom:        .5rem !default;
$legend-font-size:            1.5rem !default;
$legend-font-weight:          null !default;

$mark-padding:                0 !default;

$dt-font-weight:              $font-weight-bold !default;

$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;


// Surtitle

$surtitle-color:              $gray-700 !default;
$surtitle-font-size:          .75rem !default;
$surtitle-font-weight:        $font-weight-semibold !default;
$surtitle-text-transform:     uppercase !default;


// Articles

$article-font-size:           1rem !default;
$article-color:               $gray-800 !default;
$article-hr-spacing-y:        3em !default;
$article-text-line-height:    1.9 !default;

$article-headings-color:       $headings-color !default;
