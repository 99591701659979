.popover-tour {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-popover;
    display: block;
    min-width: 280px;
    max-width: 300px;
    // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
    // So reset our font and text properties to avoid inheriting weird values.
    @include reset-text();
    @include font-size($popover-font-size);
    // Allow breaking very long words so they don't overflow the popover's bounds
    word-wrap: break-word;
    background-color: $popover-bg;
    background-clip: padding-box;
    border: $popover-border-width solid $popover-border-color;
    @include border-radius($popover-border-radius);
    @include box-shadow($popover-box-shadow);

    .arrow {
        position: absolute;
        display: block;
        width: $popover-arrow-width;
        height: $popover-arrow-height;
        margin: 0 $popover-border-radius;

        &::before,
        &::after {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }
    }
}

.popover-tour-top {
    margin-bottom: $popover-arrow-height;

    >.arrow {
        bottom: subtract(-$popover-arrow-height, $popover-border-width);

        &::before {
            bottom: 0;
            border-width: $popover-arrow-height calc($popover-arrow-width / 2) 0;
            border-top-color: $popover-arrow-outer-color;
        }

        &::after {
            bottom: $popover-border-width;
            border-width: $popover-arrow-height calc($popover-arrow-width / 2) 0;
            border-top-color: $popover-arrow-color;
        }
    }
}

.popover-tour-right {
    margin-left: $popover-arrow-height;

    >.arrow {
        left: subtract(-$popover-arrow-height, $popover-border-width);
        width: $popover-arrow-height;
        height: $popover-arrow-width;
        margin: $popover-border-radius 0; // make sure the arrow does not touch the popover's rounded corners

        &::before {
            left: 0;
            border-width: calc($popover-arrow-width / 2) $popover-arrow-height calc($popover-arrow-width / 2) 0;
            border-right-color: $popover-arrow-outer-color;
        }

        &::after {
            left: $popover-border-width;
            border-width: calc($popover-arrow-width / 2) $popover-arrow-height calc($popover-arrow-width / 2) 0;
            border-right-color: $popover-arrow-color;
        }
    }
}

.popover-tour-bottom {
    margin-top: $popover-arrow-height;

    >.arrow {
        top: subtract(-$popover-arrow-height, $popover-border-width);

        &::before {
            top: 0;
            border-width: 0 calc($popover-arrow-width / 2) $popover-arrow-height calc($popover-arrow-width / 2);
            border-bottom-color: $popover-arrow-outer-color;
        }

        &::after {
            top: $popover-border-width;
            border-width: 0 calc($popover-arrow-width / 2) $popover-arrow-height calc($popover-arrow-width / 2);
            border-bottom-color: $popover-arrow-color;
        }
    }

    // This will remove the popover-header's border just below the arrow
    .popover-tour-header::before {
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: $popover-arrow-width;
        margin-left: -(calc($popover-arrow-width / 2));
        content: "";
        border-bottom: $popover-border-width solid $popover-header-bg;
    }
}

.popover-tour-left {
    margin-right: $popover-arrow-height;

    >.arrow {
        right: subtract(-$popover-arrow-height, $popover-border-width);
        width: $popover-arrow-height;
        height: $popover-arrow-width;
        margin: $popover-border-radius 0; // make sure the arrow does not touch the popover's rounded corners

        &::before {
            right: 0;
            border-width: calc($popover-arrow-width / 2) 0 calc($popover-arrow-width / 2) $popover-arrow-height;
            border-left-color: $popover-arrow-outer-color;
        }

        &::after {
            right: $popover-border-width;
            border-width: calc($popover-arrow-width / 2) 0 calc($popover-arrow-width / 2) $popover-arrow-height;
            border-left-color: $popover-arrow-color;
        }
    }
}

.popover-tour-auto {
    &[x-placement^="top"] {
        @extend .bs-popover-top;
    }

    &[x-placement^="right"] {
        @extend .bs-popover-right;
    }

    &[x-placement^="bottom"] {
        @extend .bs-popover-bottom;
    }

    &[x-placement^="left"] {
        @extend .bs-popover-left;
    }
}

// Offset the popover to account for the popover arrow
.popover-tour-header {
    padding: 1rem 1.25rem .5rem 1.25rem;
    margin-bottom: 0; // Reset the default from Reboot
    @include font-size($font-size-base);
    color: $popover-header-color;
    background-color: $popover-header-bg;
    // border-bottom: $popover-border-width solid darken($popover-header-bg, 5%);
    @include border-top-radius($popover-inner-border-radius);

    &:empty {
        display: none;
    }
}

.popover-tour-body {
    padding: 0 1.25rem;
    color: $popover-body-color;
}

.popover-tour-navigation {

    overflow: hidden;
    padding: 1rem 1.25rem;
    // margin-bottom:
    button {
        padding: 3px 8px;
        font-size: 11px;
    }

    [data-role="end"] {
        float: right;
    }

    [data-role="prev"],
    [data-role="next"],
    [data-role="end"] {
        cursor: pointer;

        &.disabled {
          cursor: default;
        }
    }
}
