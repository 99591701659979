// Color system

// White/Grays/Black colors
$white:    #FFF !default;
$gray-50:  #FAFAFA !default;    // Alternate background
$gray-100: #fafafb !default;    // Base background
$gray-200: #f1f1f5 !default;
$gray-300: #e2e2ea !default;
$gray-400: #d5d5dc !default;
$gray-500: #b5b5bd !default;    // Disabled text
$gray-600: #92929c !default;    // Placeholder text
$gray-700: #8492A6 !default;    // Text content
$gray-800: #44444e !default;    // Text content
$gray-900: #231642 !default;    // Text content  31113D (old dark)
$black:    #000 !default;

// Base colors
$blue:    #1b478c;
$indigo:  #31113D;
$purple:  #623394;
$pink:    #e31a8f;
$red:     #f5365c;
$orange:  #f36f21;
$yellow:  #fdb812;
$green:   #36B37E;
$teal:    #659ad1;
$cyan:    #659ad2;

// Theme colors
$primary:       $purple;
$secondary:     $gray-200;
$tertiary:      $pink;
$neutral:       $white;
$success:       $green;
$info:          $teal;
$warning:       $orange;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-900;


// Bg Soft level

$bg-soft-level:           -80%;
$bg-shade-level:           15%;
$bg-tint-level:            15%;


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white;
$body-color:                $gray-800;
$body-text-align:           null;


// Surfaces
//
// Colors used for content area background colors

$surface-colors: ();
$surface-colors: map-merge((
    "primary":      $body-bg,
    "secondary":    $gray-100,
    "tertiary":     $gray-50,
    "light":        $gray-200,
    "dark":         shade-color($dark, 7%)
), $surface-colors);
